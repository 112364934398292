import React, { useState, useEffect } from "react"
import styled, { ThemeContext } from "styled-components"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import { dimensions, colors } from "../../../../utils/styles/styles"
import { ButtonType } from "../../../resuable/button"
import useWindowSize from "../../../hooks/useWindowSize"

const StyledSeoReportImproveSeo = styled.div`
  width: 100%;
  position: relative;
  display: block;

  div.seo-report-improve-seo-bg {
    z-index: 10;
    position: relative;
    width: 100%;
  }
  div.seo-report-improve-seo-content {
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 20;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 15%;
    margin: auto auto;

    h2.seo-report-heading-container {
      color: #fcfcfc;
      font-size: 36px;
      line-height: 125%;
      font-weight: bold;
    }
    div.seo-report-improve-button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 3rem;
      a:last-child {
        margin-left: 3rem;
        button {
          border: 2px solid #000000;
          color: #333333;
          background-color: #fcfcfc;
          &:hover {
            -webkit-transition: 0.8s all;
            transition: 0.8s all;
            box-shadow: 0px 0px 20px rgba(31, 237, 255, 0.52);
          }
        }
      }
    }
  }
  @media only screen and (max-width: 555px) {
    div.seo-report-improve-seo-bg {
      display: none;
    }
    div.seo-report-improve-seo-content {
      position: relative;
      background: #2c2c2c;
      padding: 14% 12%;

      h2.seo-report-heading-container {
        font-size: 24px;
        line-height: 150%;
      }
      div.seo-report-improve-button {
        flex-direction: column;

        a {
          width: 100%;
          &:last-child {
            margin-top: 4rem;
            margin-left: 0;
            button {
              padding: 13px 36px;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 650px) {
    div.seo-report-improve-seo-content {
      padding: 14% 10%;

      h2.seo-report-heading-container {
        font-size: 28px;
        line-height: 150%;
      }
    }
  }
`

const SeoReportImproveSeo = props => {
  const data = useStaticQuery(graphql`
    query SeoImage {
      images: file(
        relativePath: { eq: "seo-report/seo-report-improve-seo.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <StyledSeoReportImproveSeo>
      {useWindowSize().width > 480 && (
        <Img
          fluid={data.images.childImageSharp.fluid}
          alt="Optimize your SEO"
          className="seo-report-improve-seo-bg"
        />
      )}
      <div className="seo-report-improve-seo-content">
        <h2 className="seo-report-heading-container">
          Small SEO mistakes will impact a lot, So your technical SEO aspects
          have to spot on.
        </h2>
        <div className="seo-report-improve-button">
          <a href="#">
            <ButtonType.Base id="seo-report-analyse-now">
              Analyse now!
            </ButtonType.Base>
          </a>
          <Link to={`/contact`}>
            <ButtonType.Base id="seo-report-get-in-touch">
              Get in touch
            </ButtonType.Base>
          </Link>
        </div>
      </div>
    </StyledSeoReportImproveSeo>
  )
}

SeoReportImproveSeo.defaultProps = {}

export default SeoReportImproveSeo
