import React from "react"
import styled, { ThemeContext } from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Section from "../../../resuable/section/section"
import { ButtonType } from "../../../resuable/button"
import { dimensions, colors } from "../../../../utils/styles/styles"

const StyledSeoReportAnalysisTool = styled.div`
  width: 100%;
  max-width: ${dimensions.siteWidth};
  .seo-report-analysis-bg {
    width: 90%;
    margin: 0 auto;
  }
  div.seo-report-analysis-content {
    width: 100%;
    padding: 0 13%;
    position: relative;
    top: -4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    h2 {
      font-size: 36px;
      font-weight: 500;
      line-height: 125%;
    }
    p {
      padding: 0 13%;
      font-size: 18px;
      line-height: 150%;
    }
    a {
      margin-top: 3rem;
    }
    @media only screen and (max-width: 480px) {
      top: -2rem;
      padding: 0 3%;
      margin-bottom: 4rem;
      h2 {
        font-size: 18px;
        font-weight: 600;
      }
      p {
        padding: 0 7%;
        font-size: 12px;
      }
    }
  }
`

const SeoReportAnalysisTool = props => {
  const data = useStaticQuery(graphql`
    query SeoAnalysisToolImg {
      images: file(relativePath: { eq: "seo-report/seo-report-tool.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Section fullwidth={true} padding="50px 10px">
      <StyledSeoReportAnalysisTool>
        <Img
          fluid={data.images.childImageSharp.fluid}
          alt="Seo Analysis Report Tool"
          className="seo-report-analysis-bg"
        />
        <div className="seo-report-analysis-content">
          <h2>
            A simplified website audit analysis tool trusted by the biggest
            brands.
          </h2>
          <p>
            Increase your website online visibility with result-oriented SEO
            Audit Report. Now identify an actionable, prioritized list of
            insights to see your webpage top in search engines for your
            customer’s searches.
          </p>
          <a href="#top">
            <ButtonType.Base>Analyse now!</ButtonType.Base>
          </a>
        </div>
      </StyledSeoReportAnalysisTool>
    </Section>
  )
}

SeoReportAnalysisTool.defaultProps = {}

export default SeoReportAnalysisTool
