export const getUserGeoLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(displayLocationInfo);
  }
  
  function displayLocationInfo(position) {
    const lng = position.coords.longitude;
    const lat = position.coords.latitude;
  
    console.log(position);
  }
}

export const getUserCountry = async () => {
  let cy = 'US';
  try {
    const res = await fetch('https://ipinfo.io/geo?token=604b9d25b76f97');
    const data = await res.json();
    cy = data.country;
  } catch(err) {
    console.log(err);
  }
  return cy;
}