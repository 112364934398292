import React from "react"
import styled from "styled-components"
import { seoProcessAnimations } from "./animations"

const StyledSeoReportProcess = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div.container__process {
    width: 100%;
    max-width: 650px;
    padding: 20px 0px 10px;
    display: flex;
    justify-content: center;
    div.container__linedot {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      span {
        display: inline-block;
      }
      span.dot {
        position: relative;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background: transparent;
        &--1 {
          animation: ${seoProcessAnimations.dots} 500ms;
          animation-delay: 500ms;
          animation-fill-mode: forwards;
        }

        &--2 {
          animation: ${seoProcessAnimations.dots} 500ms;
          animation-delay: 2s;
          animation-fill-mode: forwards;
        }

        &--3 {
          animation: ${seoProcessAnimations.dots} 0.8s;
          animation-delay: 4s;
          animation-fill-mode: forwards;
        }
      }
    }
    span.line {
      position: relative;
      left: 0px;
      height: 4px;
      width: 100%;
      max-width: calc(50% - 30px);
      background: transparent;
      &--1 {
        animation: ${seoProcessAnimations.lines} 2s;
        animation-delay: 1s;
        animation-fill-mode: forwards;
      }

      &--2 {
        animation: ${seoProcessAnimations.lines} 2s;
        animation-delay: 3s;
        animation-fill-mode: forwards;
      }
    }
  }
  div.container__processlabels {
    width: 100%;
    max-width: 750px;
    display: flex;
    justify-content: space-between;
    span {
      display: inline-block;
      font-size: 12px;
      text-align: center;
    }
  }
  @media (max-width: 480px) {
    display: none;
  }
`

const SeoReportProcess = props => {
  return (
    <StyledSeoReportProcess>
      <div className="container__process">
        <div className="container__linedot">
          <span className="dot dot--1"></span>
          <span className="line line--1"></span>
          <span className="dot dot--2"></span>
          <span className="line line--2"></span>
          <span className="dot dot--3"></span>
        </div>
      </div>
      <div className="container__processlabels">
        <span>Enter your website URL</span>
        <span>Click on analyse site</span>
        <span>Sit back and relax</span>
      </div>
    </StyledSeoReportProcess>
  )
}

SeoReportProcess.defaultProps = {}

export default SeoReportProcess
