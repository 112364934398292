import { keyframes } from "styled-components"
import { colors } from "../../../../../../utils/styles/styles"

const dots = keyframes`
  0% {
    opacity: 0.1;
    background-color: ${colors.priDark};
    transform: scale(1, 1);
  }

  50% {
    opacity: 0.5;
    background-color: ${colors.priDark};
    transform: scale(2, 2);
  }

  100% {
    opacity: 1;
    background-color: ${colors.priDark};
    transform: scale(1, 1);
  }
`

const lines = keyframes`
  0% {
    width: 0px;
    background-color: ${colors.priDark};
  }
  100% {
    width: 100%;
    background-color: ${colors.priDark};
  }
`

export const seoProcessAnimations = {
  dots,
  lines
}
