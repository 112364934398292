export const countryList = [
  { label: 'Afghanistan', value: 'AF' },
  { label: 'Åland Islands', value: 'AX' },
  { label: 'Albania', value: 'AL' },
  { label: 'Algeria', value: 'DZ' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'Andorra', value: 'AD' },
  { label: 'Angola', value: 'AO' },
  { label: 'Anguilla', value: 'AI' },
  { label: 'Antarctica', value: 'AQ' },
  { label: 'Antigua and Barbuda', value: 'AG' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Armenia', value: 'AM' },
  { label: 'Aruba', value: 'AW' },
  { label: 'Australia', value: 'AU' },
  { label: 'Austria', value: 'AT' },
  { label: 'Azerbaijan', value: 'AZ' },
  { label: 'Bahamas', value: 'BS' },
  { label: 'Bahrain', value: 'BH' },
  { label: 'Bangladesh', value: 'BD' },
  { label: 'Barbados', value: 'BB' },
  { label: 'Belarus', value: 'BY' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Belize', value: 'BZ' },
  { label: 'Benin', value: 'BJ' },
  { label: 'Bermuda', value: 'BM' },
  { label: 'Bhutan', value: 'BT' },
  { label: 'Bolivia', value: 'BO' },
  { label: 'Bosnia and Herzegovina', value: 'BA' },
  { label: 'Botswana', value: 'BW' },
  { label: 'Bouvet Island', value: 'BV' },
  { label: 'Brazil', value: 'BR' },
  { label: 'British Indian Ocean Territory', value: 'IO' },
  { label: 'Brunei Darussalam', value: 'BN' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Burkina Faso', value: 'BF' },
  { label: 'Burundi', value: 'BI' },
  { label: 'Cambodia', value: 'KH' },
  { label: 'Cameroon', value: 'CM' },
  { label: 'Canada', value: 'CA' },
  { label: 'Cape Verde', value: 'CV' },
  { label: 'Cayman Islands', value: 'KY' },
  { label: 'Central African Republic', value: 'CF' },
  { label: 'Chad', value: 'TD' },
  { label: 'Chile', value: 'CL' },
  { label: 'China', value: 'CN' },
  { label: 'Christmas Island', value: 'CX' },
  { label: 'Cocos (Keeling) Islands', value: 'CC' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Comoros', value: 'KM' },
  { label: 'Congo', value: 'CG' },
  { label: 'Congo, The Democratic Republic of the', value: 'CD' },
  { label: 'Cook Islands', value: 'CK' },
  { label: 'Costa Rica', value: 'CR' },
  { label: 'Cote D\'Ivoire', value: 'CI' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Cuba', value: 'CU' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Czech Republic', value: 'CZ' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Djibouti', value: 'DJ' },
  { label: 'Dominica', value: 'DM' },
  { label: 'Dominican Republic', value: 'DO' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Egypt', value: 'EG' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Equatorial Guinea', value: 'GQ' },
  { label: 'Eritrea', value: 'ER' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Ethiopia', value: 'ET' },
  { label: 'Falkland Islands (Malvinas)', value: 'FK' },
  { label: 'Faroe Islands', value: 'FO' },
  { label: 'Fiji', value: 'FJ' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'French Guiana', value: 'GF' },
  { label: 'French Polynesia', value: 'PF' },
  { label: 'French Southern Territories', value: 'TF' },
  { label: 'Gabon', value: 'GA' },
  { label: 'Gambia', value: 'GM' },
  { label: 'Georgia', value: 'GE' },
  { label: 'Germany', value: 'DE' },
  { label: 'Ghana', value: 'GH' },
  { label: 'Gibraltar', value: 'GI' },
  { label: 'Greece', value: 'GR' },
  { label: 'Greenland', value: 'GL' },
  { label: 'Grenada', value: 'GD' },
  { label: 'Guadeloupe', value: 'GP' },
  { label: 'Guam', value: 'GU' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Guernsey', value: 'GG' },
  { label: 'Guinea', value: 'GN' },
  { label: 'Guinea-Bissau', value: 'GW' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Haiti', value: 'HT' },
  { label: 'Heard Island and Mcdonald Islands', value: 'HM' },
  { label: 'Holy See (Vatican City State)', value: 'VA' },
  { label: 'Honduras', value: 'HN' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Iceland', value: 'IS' },
  { label: 'India', value: 'IN' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Iran, Islamic Republic Of', value: 'IR' },
  { label: 'Iraq', value: 'IQ' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Isle of Man', value: 'IM' },
  { label: 'Israel', value: 'IL' },
  { label: 'Italy', value: 'IT' },
  { label: 'Jamaica', value: 'JM' },
  { label: 'Japan', value: 'JP' },
  { label: 'Jersey', value: 'JE' },
  { label: 'Jordan', value: 'JO' },
  { label: 'Kazakhstan', value: 'KZ' },
  { label: 'Kenya', value: 'KE' },
  { label: 'Kiribati', value: 'KI' },
  { label: 'Korea, Democratic People\'S Republic of', value: 'KP' },
  { label: 'Korea, Republic of', value: 'KR' },
  { label: 'Kuwait', value: 'KW' },
  { label: 'Kyrgyzstan', value: 'KG' },
  { label: 'Lao People\'S Democratic Republic', value: 'LA' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Lebanon', value: 'LB' },
  { label: 'Lesotho', value: 'LS' },
  { label: 'Liberia', value: 'LR' },
  { label: 'Libyan Arab Jamahiriya', value: 'LY' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Macao', value: 'MO' },
  { label: 'Macedonia, The Former Yugoslav Republic of', value: 'MK' },
  { label: 'Madagascar', value: 'MG' },
  { label: 'Malawi', value: 'MW' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Maldives', value: 'MV' },
  { label: 'Mali', value: 'ML' },
  { label: 'Malta', value: 'MT' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Martinique', value: 'MQ' },
  { label: 'Mauritania', value: 'MR' },
  { label: 'Mauritius', value: 'MU' },
  { label: 'Mayotte', value: 'YT' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Micronesia, Federated States of', value: 'FM' },
  { label: 'Moldova, Republic of', value: 'MD' },
  { label: 'Monaco', value: 'MC' },
  { label: 'Mongolia', value: 'MN' },
  { label: 'Montserrat', value: 'MS' },
  { label: 'Morocco', value: 'MA' },
  { label: 'Mozambique', value: 'MZ' },
  { label: 'Myanmar', value: 'MM' },
  { label: 'Namibia', value: 'NA' },
  { label: 'Nauru', value: 'NR' },
  { label: 'Nepal', value: 'NP' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'Netherlands Antilles', value: 'AN' },
  { label: 'New Caledonia', value: 'NC' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Nicaragua', value: 'NI' },
  { label: 'Niger', value: 'NE' },
  { label: 'Nigeria', value: 'NG' },
  { label: 'Niue', value: 'NU' },
  { label: 'Norfolk Island', value: 'NF' },
  { label: 'Northern Mariana Islands', value: 'MP' },
  { label: 'Norway', value: 'NO' },
  { label: 'Oman', value: 'OM' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Palau', value: 'PW' },
  { label: 'Palestinian Territory, Occupied', value: 'PS' },
  { label: 'Panama', value: 'PA' },
  { label: 'Papua New Guinea', value: 'PG' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'Peru', value: 'PE' },
  { label: 'Philippines', value: 'PH' },
  { label: 'Pitcairn', value: 'PN' },
  { label: 'Poland', value: 'PL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Qatar', value: 'QA' },
  { label: 'Reunion', value: 'RE' },
  { label: 'Romania', value: 'RO' },
  { label: 'Russian Federation', value: 'RU' },
  { label: 'RWANDA', value: 'RW' },
  { label: 'Saint Helena', value: 'SH' },
  { label: 'Saint Kitts and Nevis', value: 'KN' },
  { label: 'Saint Lucia', value: 'LC' },
  { label: 'Saint Pierre and Miquelon', value: 'PM' },
  { label: 'Saint Vincent and the Grenadines', value: 'VC' },
  { label: 'Samoa', value: 'WS' },
  { label: 'San Marino', value: 'SM' },
  { label: 'Sao Tome and Principe', value: 'ST' },
  { label: 'Saudi Arabia', value: 'SA' },
  { label: 'Senegal', value: 'SN' },
  { label: 'Serbia and Montenegro', value: 'CS' },
  { label: 'Seychelles', value: 'SC' },
  { label: 'Sierra Leone', value: 'SL' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Slovakia', value: 'SK' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Solomon Islands', value: 'SB' },
  { label: 'Somalia', value: 'SO' },
  { label: 'South Africa', value: 'ZA' },
  { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
  { label: 'Spain', value: 'ES' },
  { label: 'Sri Lanka', value: 'LK' },
  { label: 'Sudan', value: 'SD' },
  { label: 'Surilabel', value: 'SR' },
  { label: 'Svalbard and Jan Mayen', value: 'SJ' },
  { label: 'Swaziland', value: 'SZ' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Syrian Arab Republic', value: 'SY' },
  { label: 'Taiwan, Province of China', value: 'TW' },
  { label: 'Tajikistan', value: 'TJ' },
  { label: 'Tanzania, United Republic of', value: 'TZ' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Timor-Leste', value: 'TL' },
  { label: 'Togo', value: 'TG' },
  { label: 'Tokelau', value: 'TK' },
  { label: 'Tonga', value: 'TO' },
  { label: 'Trinidad and Tobago', value: 'TT' },
  { label: 'Tunisia', value: 'TN' },
  { label: 'Turkey', value: 'TR' },
  { label: 'Turkmenistan', value: 'TM' },
  { label: 'Turks and Caicos Islands', value: 'TC' },
  { label: 'Tuvalu', value: 'TV' },
  { label: 'Uganda', value: 'UG' },
  { label: 'Ukraine', value: 'UA' },
  { label: 'United Arab Emirates', value: 'AE' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'United States', value: 'US' },
  { label: 'United States Minor Outlying Islands', value: 'UM' },
  { label: 'Uruguay', value: 'UY' },
  { label: 'Uzbekistan', value: 'UZ' },
  { label: 'Vanuatu', value: 'VU' },
  { label: 'Venezuela', value: 'VE' },
  { label: 'Viet Nam', value: 'VN' },
  { label: 'Virgin Islands, British', value: 'VG' },
  { label: 'Virgin Islands, U.S.', value: 'VI' },
  { label: 'Wallis and Futuna', value: 'WF' },
  { label: 'Western Sahara', value: 'EH' },
  { label: 'Yemen', value: 'YE' },
  { label: 'Zambia', value: 'ZM' },
  { label: 'Zimbabwe', value: 'ZW' }
]

export const countryListWithFlag = [
  {
    "value": "AC",
    "unicode": "U+1F1E6 U+1F1E8",
    "label": "🇦🇨 Ascension Island",
    "emoji": "🇦🇨"
  },
  {
    "value": "AD",
    "unicode": "U+1F1E6 U+1F1E9",
    "label": "Andorra",
    "emoji": "🇦🇩"
  },
  {
    "value": "AE",
    "unicode": "U+1F1E6 U+1F1EA",
    "label": "United Arab Emirates",
    "emoji": "🇦🇪"
  },
  {
    "value": "AF",
    "unicode": "U+1F1E6 U+1F1EB",
    "label": "Afghanistan",
    "emoji": "🇦🇫"
  },
  {
    "value": "AG",
    "unicode": "U+1F1E6 U+1F1EC",
    "label": "Antigua & Barbuda",
    "emoji": "🇦🇬"
  },
  {
    "value": "AI",
    "unicode": "U+1F1E6 U+1F1EE",
    "label": "Anguilla",
    "emoji": "🇦🇮"
  },
  {
    "value": "AL",
    "unicode": "U+1F1E6 U+1F1F1",
    "label": "Albania",
    "emoji": "🇦🇱"
  },
  {
    "value": "AM",
    "unicode": "U+1F1E6 U+1F1F2",
    "label": "Armenia",
    "emoji": "🇦🇲"
  },
  {
    "value": "AO",
    "unicode": "U+1F1E6 U+1F1F4",
    "label": "Angola",
    "emoji": "🇦🇴"
  },
  {
    "value": "AQ",
    "unicode": "U+1F1E6 U+1F1F6",
    "label": "Antarctica",
    "emoji": "🇦🇶"
  },
  {
    "value": "AR",
    "unicode": "U+1F1E6 U+1F1F7",
    "label": "Argentina",
    "emoji": "🇦🇷"
  },
  {
    "value": "AS",
    "unicode": "U+1F1E6 U+1F1F8",
    "label": "American Samoa",
    "emoji": "🇦🇸"
  },
  {
    "value": "AT",
    "unicode": "U+1F1E6 U+1F1F9",
    "label": "Austria",
    "emoji": "🇦🇹"
  },
  {
    "value": "AU",
    "unicode": "U+1F1E6 U+1F1FA",
    "label": "Australia",
    "emoji": "🇦🇺"
  },
  {
    "value": "AW",
    "unicode": "U+1F1E6 U+1F1FC",
    "label": "Aruba",
    "emoji": "🇦🇼"
  },
  {
    "value": "AX",
    "unicode": "U+1F1E6 U+1F1FD",
    "label": "Åland Islands",
    "emoji": "🇦🇽"
  },
  {
    "value": "AZ",
    "unicode": "U+1F1E6 U+1F1FF",
    "label": "Azerbaijan",
    "emoji": "🇦🇿"
  },
  {
    "value": "BA",
    "unicode": "U+1F1E7 U+1F1E6",
    "label": "Bosnia & Herzegovina",
    "emoji": "🇧🇦"
  },
  {
    "value": "BB",
    "unicode": "U+1F1E7 U+1F1E7",
    "label": "Barbados",
    "emoji": "🇧🇧"
  },
  {
    "value": "BD",
    "unicode": "U+1F1E7 U+1F1E9",
    "label": "Bangladesh",
    "emoji": "🇧🇩"
  },
  {
    "value": "BE",
    "unicode": "U+1F1E7 U+1F1EA",
    "label": "Belgium",
    "emoji": "🇧🇪"
  },
  {
    "value": "BF",
    "unicode": "U+1F1E7 U+1F1EB",
    "label": "Burkina Faso",
    "emoji": "🇧🇫"
  },
  {
    "value": "BG",
    "unicode": "U+1F1E7 U+1F1EC",
    "label": "Bulgaria",
    "emoji": "🇧🇬"
  },
  {
    "value": "BH",
    "unicode": "U+1F1E7 U+1F1ED",
    "label": "Bahrain",
    "emoji": "🇧🇭"
  },
  {
    "value": "BI",
    "unicode": "U+1F1E7 U+1F1EE",
    "label": "Burundi",
    "emoji": "🇧🇮"
  },
  {
    "value": "BJ",
    "unicode": "U+1F1E7 U+1F1EF",
    "label": "Benin",
    "emoji": "🇧🇯"
  },
  {
    "value": "BL",
    "unicode": "U+1F1E7 U+1F1F1",
    "label": "St. Barthélemy",
    "emoji": "🇧🇱"
  },
  {
    "value": "BM",
    "unicode": "U+1F1E7 U+1F1F2",
    "label": "Bermuda",
    "emoji": "🇧🇲"
  },
  {
    "value": "BN",
    "unicode": "U+1F1E7 U+1F1F3",
    "label": "Brunei",
    "emoji": "🇧🇳"
  },
  {
    "value": "BO",
    "unicode": "U+1F1E7 U+1F1F4",
    "label": "Bolivia",
    "emoji": "🇧🇴"
  },
  {
    "value": "BQ",
    "unicode": "U+1F1E7 U+1F1F6",
    "label": "Caribbean Netherlands",
    "emoji": "🇧🇶"
  },
  {
    "value": "BR",
    "unicode": "U+1F1E7 U+1F1F7",
    "label": "Brazil",
    "emoji": "🇧🇷"
  },
  {
    "value": "BS",
    "unicode": "U+1F1E7 U+1F1F8",
    "label": "Bahamas",
    "emoji": "🇧🇸"
  },
  {
    "value": "BT",
    "unicode": "U+1F1E7 U+1F1F9",
    "label": "Bhutan",
    "emoji": "🇧🇹"
  },
  {
    "value": "BV",
    "unicode": "U+1F1E7 U+1F1FB",
    "label": "Bouvet Island",
    "emoji": "🇧🇻"
  },
  {
    "value": "BW",
    "unicode": "U+1F1E7 U+1F1FC",
    "label": "Botswana",
    "emoji": "🇧🇼"
  },
  {
    "value": "BY",
    "unicode": "U+1F1E7 U+1F1FE",
    "label": "Belarus",
    "emoji": "🇧🇾"
  },
  {
    "value": "BZ",
    "unicode": "U+1F1E7 U+1F1FF",
    "label": "Belize",
    "emoji": "🇧🇿"
  },
  {
    "value": "CA",
    "unicode": "U+1F1E8 U+1F1E6",
    "label": "Canada",
    "emoji": "🇨🇦"
  },
  {
    "value": "CC",
    "unicode": "U+1F1E8 U+1F1E8",
    "label": "Cocos (Keeling) Islands",
    "emoji": "🇨🇨"
  },
  {
    "value": "CD",
    "unicode": "U+1F1E8 U+1F1E9",
    "label": "Congo - Kinshasa",
    "emoji": "🇨🇩"
  },
  {
    "value": "CF",
    "unicode": "U+1F1E8 U+1F1EB",
    "label": "Central African Republic",
    "emoji": "🇨🇫"
  },
  {
    "value": "CG",
    "unicode": "U+1F1E8 U+1F1EC",
    "label": "Congo - Brazzaville",
    "emoji": "🇨🇬"
  },
  {
    "value": "CH",
    "unicode": "U+1F1E8 U+1F1ED",
    "label": "Switzerland",
    "emoji": "🇨🇭"
  },
  {
    "value": "CI",
    "unicode": "U+1F1E8 U+1F1EE",
    "label": "Côte d’Ivoire",
    "emoji": "🇨🇮"
  },
  {
    "value": "CK",
    "unicode": "U+1F1E8 U+1F1F0",
    "label": "Cook Islands",
    "emoji": "🇨🇰"
  },
  {
    "value": "CL",
    "unicode": "U+1F1E8 U+1F1F1",
    "label": "Chile",
    "emoji": "🇨🇱"
  },
  {
    "value": "CM",
    "unicode": "U+1F1E8 U+1F1F2",
    "label": "Cameroon",
    "emoji": "🇨🇲"
  },
  {
    "value": "CN",
    "unicode": "U+1F1E8 U+1F1F3",
    "label": "China",
    "emoji": "🇨🇳"
  },
  {
    "value": "CO",
    "unicode": "U+1F1E8 U+1F1F4",
    "label": "Colombia",
    "emoji": "🇨🇴"
  },
  {
    "value": "CP",
    "unicode": "U+1F1E8 U+1F1F5",
    "label": "Clipperton Island",
    "emoji": "🇨🇵"
  },
  {
    "value": "CR",
    "unicode": "U+1F1E8 U+1F1F7",
    "label": "Costa Rica",
    "emoji": "🇨🇷"
  },
  {
    "value": "CU",
    "unicode": "U+1F1E8 U+1F1FA",
    "label": "Cuba",
    "emoji": "🇨🇺"
  },
  {
    "value": "CV",
    "unicode": "U+1F1E8 U+1F1FB",
    "label": "Cape Verde",
    "emoji": "🇨🇻"
  },
  {
    "value": "CW",
    "unicode": "U+1F1E8 U+1F1FC",
    "label": "Curaçao",
    "emoji": "🇨🇼"
  },
  {
    "value": "CX",
    "unicode": "U+1F1E8 U+1F1FD",
    "label": "Christmas Island",
    "emoji": "🇨🇽"
  },
  {
    "value": "CY",
    "unicode": "U+1F1E8 U+1F1FE",
    "label": "Cyprus",
    "emoji": "🇨🇾"
  },
  {
    "value": "CZ",
    "unicode": "U+1F1E8 U+1F1FF",
    "label": "Czechia",
    "emoji": "🇨🇿"
  },
  {
    "value": "DE",
    "unicode": "U+1F1E9 U+1F1EA",
    "label": "Germany",
    "emoji": "🇩🇪"
  },
  {
    "value": "DG",
    "unicode": "U+1F1E9 U+1F1EC",
    "label": "Diego Garcia",
    "emoji": "🇩🇬"
  },
  {
    "value": "DJ",
    "unicode": "U+1F1E9 U+1F1EF",
    "label": "Djibouti",
    "emoji": "🇩🇯"
  },
  {
    "value": "DK",
    "unicode": "U+1F1E9 U+1F1F0",
    "label": "Denmark",
    "emoji": "🇩🇰"
  },
  {
    "value": "DM",
    "unicode": "U+1F1E9 U+1F1F2",
    "label": "Dominica",
    "emoji": "🇩🇲"
  },
  {
    "value": "DO",
    "unicode": "U+1F1E9 U+1F1F4",
    "label": "Dominican Republic",
    "emoji": "🇩🇴"
  },
  {
    "value": "DZ",
    "unicode": "U+1F1E9 U+1F1FF",
    "label": "Algeria",
    "emoji": "🇩🇿"
  },
  {
    "value": "EA",
    "unicode": "U+1F1EA U+1F1E6",
    "label": "Ceuta & Melilla",
    "emoji": "🇪🇦"
  },
  {
    "value": "EC",
    "unicode": "U+1F1EA U+1F1E8",
    "label": "Ecuador",
    "emoji": "🇪🇨"
  },
  {
    "value": "EE",
    "unicode": "U+1F1EA U+1F1EA",
    "label": "Estonia",
    "emoji": "🇪🇪"
  },
  {
    "value": "EG",
    "unicode": "U+1F1EA U+1F1EC",
    "label": "Egypt",
    "emoji": "🇪🇬"
  },
  {
    "value": "EH",
    "unicode": "U+1F1EA U+1F1ED",
    "label": "Western Sahara",
    "emoji": "🇪🇭"
  },
  {
    "value": "ER",
    "unicode": "U+1F1EA U+1F1F7",
    "label": "Eritrea",
    "emoji": "🇪🇷"
  },
  {
    "value": "ES",
    "unicode": "U+1F1EA U+1F1F8",
    "label": "Spain",
    "emoji": "🇪🇸"
  },
  {
    "value": "ET",
    "unicode": "U+1F1EA U+1F1F9",
    "label": "Ethiopia",
    "emoji": "🇪🇹"
  },
  {
    "value": "EU",
    "unicode": "U+1F1EA U+1F1FA",
    "label": "European Union",
    "emoji": "🇪🇺"
  },
  {
    "value": "FI",
    "unicode": "U+1F1EB U+1F1EE",
    "label": "Finland",
    "emoji": "🇫🇮"
  },
  {
    "value": "FJ",
    "unicode": "U+1F1EB U+1F1EF",
    "label": "Fiji",
    "emoji": "🇫🇯"
  },
  {
    "value": "FK",
    "unicode": "U+1F1EB U+1F1F0",
    "label": "Falkland Islands",
    "emoji": "🇫🇰"
  },
  {
    "value": "FM",
    "unicode": "U+1F1EB U+1F1F2",
    "label": "Micronesia",
    "emoji": "🇫🇲"
  },
  {
    "value": "FO",
    "unicode": "U+1F1EB U+1F1F4",
    "label": "Faroe Islands",
    "emoji": "🇫🇴"
  },
  {
    "value": "FR",
    "unicode": "U+1F1EB U+1F1F7",
    "label": "France",
    "emoji": "🇫🇷"
  },
  {
    "value": "GA",
    "unicode": "U+1F1EC U+1F1E6",
    "label": "Gabon",
    "emoji": "🇬🇦"
  },
  {
    "value": "GB",
    "unicode": "U+1F1EC U+1F1E7",
    "label": "United Kingdom",
    "emoji": "🇬🇧"
  },
  {
    "value": "GD",
    "unicode": "U+1F1EC U+1F1E9",
    "label": "Grenada",
    "emoji": "🇬🇩"
  },
  {
    "value": "GE",
    "unicode": "U+1F1EC U+1F1EA",
    "label": "Georgia",
    "emoji": "🇬🇪"
  },
  {
    "value": "GF",
    "unicode": "U+1F1EC U+1F1EB",
    "label": "French Guiana",
    "emoji": "🇬🇫"
  },
  {
    "value": "GG",
    "unicode": "U+1F1EC U+1F1EC",
    "label": "Guernsey",
    "emoji": "🇬🇬"
  },
  {
    "value": "GH",
    "unicode": "U+1F1EC U+1F1ED",
    "label": "Ghana",
    "emoji": "🇬🇭"
  },
  {
    "value": "GI",
    "unicode": "U+1F1EC U+1F1EE",
    "label": "Gibraltar",
    "emoji": "🇬🇮"
  },
  {
    "value": "GL",
    "unicode": "U+1F1EC U+1F1F1",
    "label": "Greenland",
    "emoji": "🇬🇱"
  },
  {
    "value": "GM",
    "unicode": "U+1F1EC U+1F1F2",
    "label": "Gambia",
    "emoji": "🇬🇲"
  },
  {
    "value": "GN",
    "unicode": "U+1F1EC U+1F1F3",
    "label": "Guinea",
    "emoji": "🇬🇳"
  },
  {
    "value": "GP",
    "unicode": "U+1F1EC U+1F1F5",
    "label": "Guadeloupe",
    "emoji": "🇬🇵"
  },
  {
    "value": "GQ",
    "unicode": "U+1F1EC U+1F1F6",
    "label": "Equatorial Guinea",
    "emoji": "🇬🇶"
  },
  {
    "value": "GR",
    "unicode": "U+1F1EC U+1F1F7",
    "label": "Greece",
    "emoji": "🇬🇷"
  },
  {
    "value": "GS",
    "unicode": "U+1F1EC U+1F1F8",
    "label": "South Georgia & South Sandwich Islands",
    "emoji": "🇬🇸"
  },
  {
    "value": "GT",
    "unicode": "U+1F1EC U+1F1F9",
    "label": "Guatemala",
    "emoji": "🇬🇹"
  },
  {
    "value": "GU",
    "unicode": "U+1F1EC U+1F1FA",
    "label": "Guam",
    "emoji": "🇬🇺"
  },
  {
    "value": "GW",
    "unicode": "U+1F1EC U+1F1FC",
    "label": "Guinea-Bissau",
    "emoji": "🇬🇼"
  },
  {
    "value": "GY",
    "unicode": "U+1F1EC U+1F1FE",
    "label": "Guyana",
    "emoji": "🇬🇾"
  },
  {
    "value": "HK",
    "unicode": "U+1F1ED U+1F1F0",
    "label": "Hong Kong SAR China",
    "emoji": "🇭🇰"
  },
  {
    "value": "HM",
    "unicode": "U+1F1ED U+1F1F2",
    "label": "Heard & McDonald Islands",
    "emoji": "🇭🇲"
  },
  {
    "value": "HN",
    "unicode": "U+1F1ED U+1F1F3",
    "label": "Honduras",
    "emoji": "🇭🇳"
  },
  {
    "value": "HR",
    "unicode": "U+1F1ED U+1F1F7",
    "label": "Croatia",
    "emoji": "🇭🇷"
  },
  {
    "value": "HT",
    "unicode": "U+1F1ED U+1F1F9",
    "label": "Haiti",
    "emoji": "🇭🇹"
  },
  {
    "value": "HU",
    "unicode": "U+1F1ED U+1F1FA",
    "label": "Hungary",
    "emoji": "🇭🇺"
  },
  {
    "value": "IC",
    "unicode": "U+1F1EE U+1F1E8",
    "label": "Canary Islands",
    "emoji": "🇮🇨"
  },
  {
    "value": "ID",
    "unicode": "U+1F1EE U+1F1E9",
    "label": "Indonesia",
    "emoji": "🇮🇩"
  },
  {
    "value": "IE",
    "unicode": "U+1F1EE U+1F1EA",
    "label": "Ireland",
    "emoji": "🇮🇪"
  },
  {
    "value": "IL",
    "unicode": "U+1F1EE U+1F1F1",
    "label": "Israel",
    "emoji": "🇮🇱"
  },
  {
    "value": "IM",
    "unicode": "U+1F1EE U+1F1F2",
    "label": "Isle of Man",
    "emoji": "🇮🇲"
  },
  {
    "value": "IN",
    "unicode": "U+1F1EE U+1F1F3",
    "label": "India",
    "emoji": "🇮🇳"
  },
  {
    "value": "IO",
    "unicode": "U+1F1EE U+1F1F4",
    "label": "British Indian Ocean Territory",
    "emoji": "🇮🇴"
  },
  {
    "value": "IQ",
    "unicode": "U+1F1EE U+1F1F6",
    "label": "Iraq",
    "emoji": "🇮🇶"
  },
  {
    "value": "IR",
    "unicode": "U+1F1EE U+1F1F7",
    "label": "Iran",
    "emoji": "🇮🇷"
  },
  {
    "value": "IS",
    "unicode": "U+1F1EE U+1F1F8",
    "label": "Iceland",
    "emoji": "🇮🇸"
  },
  {
    "value": "IT",
    "unicode": "U+1F1EE U+1F1F9",
    "label": "Italy",
    "emoji": "🇮🇹"
  },
  {
    "value": "JE",
    "unicode": "U+1F1EF U+1F1EA",
    "label": "Jersey",
    "emoji": "🇯🇪"
  },
  {
    "value": "JM",
    "unicode": "U+1F1EF U+1F1F2",
    "label": "Jamaica",
    "emoji": "🇯🇲"
  },
  {
    "value": "JO",
    "unicode": "U+1F1EF U+1F1F4",
    "label": "Jordan",
    "emoji": "🇯🇴"
  },
  {
    "value": "JP",
    "unicode": "U+1F1EF U+1F1F5",
    "label": "Japan",
    "emoji": "🇯🇵"
  },
  {
    "value": "KE",
    "unicode": "U+1F1F0 U+1F1EA",
    "label": "Kenya",
    "emoji": "🇰🇪"
  },
  {
    "value": "KG",
    "unicode": "U+1F1F0 U+1F1EC",
    "label": "Kyrgyzstan",
    "emoji": "🇰🇬"
  },
  {
    "value": "KH",
    "unicode": "U+1F1F0 U+1F1ED",
    "label": "Cambodia",
    "emoji": "🇰🇭"
  },
  {
    "value": "KI",
    "unicode": "U+1F1F0 U+1F1EE",
    "label": "Kiribati",
    "emoji": "🇰🇮"
  },
  {
    "value": "KM",
    "unicode": "U+1F1F0 U+1F1F2",
    "label": "Comoros",
    "emoji": "🇰🇲"
  },
  {
    "value": "KN",
    "unicode": "U+1F1F0 U+1F1F3",
    "label": "St. Kitts & Nevis",
    "emoji": "🇰🇳"
  },
  {
    "value": "KP",
    "unicode": "U+1F1F0 U+1F1F5",
    "label": "North Korea",
    "emoji": "🇰🇵"
  },
  {
    "value": "KR",
    "unicode": "U+1F1F0 U+1F1F7",
    "label": "South Korea",
    "emoji": "🇰🇷"
  },
  {
    "value": "KW",
    "unicode": "U+1F1F0 U+1F1FC",
    "label": "Kuwait",
    "emoji": "🇰🇼"
  },
  {
    "value": "KY",
    "unicode": "U+1F1F0 U+1F1FE",
    "label": "Cayman Islands",
    "emoji": "🇰🇾"
  },
  {
    "value": "KZ",
    "unicode": "U+1F1F0 U+1F1FF",
    "label": "Kazakhstan",
    "emoji": "🇰🇿"
  },
  {
    "value": "LA",
    "unicode": "U+1F1F1 U+1F1E6",
    "label": "Laos",
    "emoji": "🇱🇦"
  },
  {
    "value": "LB",
    "unicode": "U+1F1F1 U+1F1E7",
    "label": "Lebanon",
    "emoji": "🇱🇧"
  },
  {
    "value": "LC",
    "unicode": "U+1F1F1 U+1F1E8",
    "label": "St. Lucia",
    "emoji": "🇱🇨"
  },
  {
    "value": "LI",
    "unicode": "U+1F1F1 U+1F1EE",
    "label": "Liechtenstein",
    "emoji": "🇱🇮"
  },
  {
    "value": "LK",
    "unicode": "U+1F1F1 U+1F1F0",
    "label": "Sri Lanka",
    "emoji": "🇱🇰"
  },
  {
    "value": "LR",
    "unicode": "U+1F1F1 U+1F1F7",
    "label": "Liberia",
    "emoji": "🇱🇷"
  },
  {
    "value": "LS",
    "unicode": "U+1F1F1 U+1F1F8",
    "label": "Lesotho",
    "emoji": "🇱🇸"
  },
  {
    "value": "LT",
    "unicode": "U+1F1F1 U+1F1F9",
    "label": "Lithuania",
    "emoji": "🇱🇹"
  },
  {
    "value": "LU",
    "unicode": "U+1F1F1 U+1F1FA",
    "label": "Luxembourg",
    "emoji": "🇱🇺"
  },
  {
    "value": "LV",
    "unicode": "U+1F1F1 U+1F1FB",
    "label": "Latvia",
    "emoji": "🇱🇻"
  },
  {
    "value": "LY",
    "unicode": "U+1F1F1 U+1F1FE",
    "label": "Libya",
    "emoji": "🇱🇾"
  },
  {
    "value": "MA",
    "unicode": "U+1F1F2 U+1F1E6",
    "label": "Morocco",
    "emoji": "🇲🇦"
  },
  {
    "value": "MC",
    "unicode": "U+1F1F2 U+1F1E8",
    "label": "Monaco",
    "emoji": "🇲🇨"
  },
  {
    "value": "MD",
    "unicode": "U+1F1F2 U+1F1E9",
    "label": "Moldova",
    "emoji": "🇲🇩"
  },
  {
    "value": "ME",
    "unicode": "U+1F1F2 U+1F1EA",
    "label": "Montenegro",
    "emoji": "🇲🇪"
  },
  {
    "value": "MF",
    "unicode": "U+1F1F2 U+1F1EB",
    "label": "St. Martin",
    "emoji": "🇲🇫"
  },
  {
    "value": "MG",
    "unicode": "U+1F1F2 U+1F1EC",
    "label": "Madagascar",
    "emoji": "🇲🇬"
  },
  {
    "value": "MH",
    "unicode": "U+1F1F2 U+1F1ED",
    "label": "Marshall Islands",
    "emoji": "🇲🇭"
  },
  {
    "value": "MK",
    "unicode": "U+1F1F2 U+1F1F0",
    "label": "Macedonia",
    "emoji": "🇲🇰"
  },
  {
    "value": "ML",
    "unicode": "U+1F1F2 U+1F1F1",
    "label": "Mali",
    "emoji": "🇲🇱"
  },
  {
    "value": "MM",
    "unicode": "U+1F1F2 U+1F1F2",
    "label": "Myanmar (Burma)",
    "emoji": "🇲🇲"
  },
  {
    "value": "MN",
    "unicode": "U+1F1F2 U+1F1F3",
    "label": "Mongolia",
    "emoji": "🇲🇳"
  },
  {
    "value": "MO",
    "unicode": "U+1F1F2 U+1F1F4",
    "label": "Macau SAR China",
    "emoji": "🇲🇴"
  },
  {
    "value": "MP",
    "unicode": "U+1F1F2 U+1F1F5",
    "label": "Northern Mariana Islands",
    "emoji": "🇲🇵"
  },
  {
    "value": "MQ",
    "unicode": "U+1F1F2 U+1F1F6",
    "label": "Martinique",
    "emoji": "🇲🇶"
  },
  {
    "value": "MR",
    "unicode": "U+1F1F2 U+1F1F7",
    "label": "Mauritania",
    "emoji": "🇲🇷"
  },
  {
    "value": "MS",
    "unicode": "U+1F1F2 U+1F1F8",
    "label": "Montserrat",
    "emoji": "🇲🇸"
  },
  {
    "value": "MT",
    "unicode": "U+1F1F2 U+1F1F9",
    "label": "Malta",
    "emoji": "🇲🇹"
  },
  {
    "value": "MU",
    "unicode": "U+1F1F2 U+1F1FA",
    "label": "Mauritius",
    "emoji": "🇲🇺"
  },
  {
    "value": "MV",
    "unicode": "U+1F1F2 U+1F1FB",
    "label": "Maldives",
    "emoji": "🇲🇻"
  },
  {
    "value": "MW",
    "unicode": "U+1F1F2 U+1F1FC",
    "label": "Malawi",
    "emoji": "🇲🇼"
  },
  {
    "value": "MX",
    "unicode": "U+1F1F2 U+1F1FD",
    "label": "Mexico",
    "emoji": "🇲🇽"
  },
  {
    "value": "MY",
    "unicode": "U+1F1F2 U+1F1FE",
    "label": "Malaysia",
    "emoji": "🇲🇾"
  },
  {
    "value": "MZ",
    "unicode": "U+1F1F2 U+1F1FF",
    "label": "Mozambique",
    "emoji": "🇲🇿"
  },
  {
    "value": "NA",
    "unicode": "U+1F1F3 U+1F1E6",
    "label": "Namibia",
    "emoji": "🇳🇦"
  },
  {
    "value": "NC",
    "unicode": "U+1F1F3 U+1F1E8",
    "label": "New Caledonia",
    "emoji": "🇳🇨"
  },
  {
    "value": "NE",
    "unicode": "U+1F1F3 U+1F1EA",
    "label": "Niger",
    "emoji": "🇳🇪"
  },
  {
    "value": "NF",
    "unicode": "U+1F1F3 U+1F1EB",
    "label": "Norfolk Island",
    "emoji": "🇳🇫"
  },
  {
    "value": "NG",
    "unicode": "U+1F1F3 U+1F1EC",
    "label": "Nigeria",
    "emoji": "🇳🇬"
  },
  {
    "value": "NI",
    "unicode": "U+1F1F3 U+1F1EE",
    "label": "Nicaragua",
    "emoji": "🇳🇮"
  },
  {
    "value": "NL",
    "unicode": "U+1F1F3 U+1F1F1",
    "label": "Netherlands",
    "emoji": "🇳🇱"
  },
  {
    "value": "NO",
    "unicode": "U+1F1F3 U+1F1F4",
    "label": "Norway",
    "emoji": "🇳🇴"
  },
  {
    "value": "NP",
    "unicode": "U+1F1F3 U+1F1F5",
    "label": "Nepal",
    "emoji": "🇳🇵"
  },
  {
    "value": "NR",
    "unicode": "U+1F1F3 U+1F1F7",
    "label": "Nauru",
    "emoji": "🇳🇷"
  },
  {
    "value": "NU",
    "unicode": "U+1F1F3 U+1F1FA",
    "label": "Niue",
    "emoji": "🇳🇺"
  },
  {
    "value": "NZ",
    "unicode": "U+1F1F3 U+1F1FF",
    "label": "New Zealand",
    "emoji": "🇳🇿"
  },
  {
    "value": "OM",
    "unicode": "U+1F1F4 U+1F1F2",
    "label": "Oman",
    "emoji": "🇴🇲"
  },
  {
    "value": "PA",
    "unicode": "U+1F1F5 U+1F1E6",
    "label": "Panama",
    "emoji": "🇵🇦"
  },
  {
    "value": "PE",
    "unicode": "U+1F1F5 U+1F1EA",
    "label": "Peru",
    "emoji": "🇵🇪"
  },
  {
    "value": "PF",
    "unicode": "U+1F1F5 U+1F1EB",
    "label": "French Polynesia",
    "emoji": "🇵🇫"
  },
  {
    "value": "PG",
    "unicode": "U+1F1F5 U+1F1EC",
    "label": "Papua New Guinea",
    "emoji": "🇵🇬"
  },
  {
    "value": "PH",
    "unicode": "U+1F1F5 U+1F1ED",
    "label": "Philippines",
    "emoji": "🇵🇭"
  },
  {
    "value": "PK",
    "unicode": "U+1F1F5 U+1F1F0",
    "label": "Pakistan",
    "emoji": "🇵🇰"
  },
  {
    "value": "PL",
    "unicode": "U+1F1F5 U+1F1F1",
    "label": "Poland",
    "emoji": "🇵🇱"
  },
  {
    "value": "PM",
    "unicode": "U+1F1F5 U+1F1F2",
    "label": "St. Pierre & Miquelon",
    "emoji": "🇵🇲"
  },
  {
    "value": "PN",
    "unicode": "U+1F1F5 U+1F1F3",
    "label": "Pitcairn Islands",
    "emoji": "🇵🇳"
  },
  {
    "value": "PR",
    "unicode": "U+1F1F5 U+1F1F7",
    "label": "Puerto Rico",
    "emoji": "🇵🇷"
  },
  {
    "value": "PS",
    "unicode": "U+1F1F5 U+1F1F8",
    "label": "Palestinian Territories",
    "emoji": "🇵🇸"
  },
  {
    "value": "PT",
    "unicode": "U+1F1F5 U+1F1F9",
    "label": "Portugal",
    "emoji": "🇵🇹"
  },
  {
    "value": "PW",
    "unicode": "U+1F1F5 U+1F1FC",
    "label": "Palau",
    "emoji": "🇵🇼"
  },
  {
    "value": "PY",
    "unicode": "U+1F1F5 U+1F1FE",
    "label": "Paraguay",
    "emoji": "🇵🇾"
  },
  {
    "value": "QA",
    "unicode": "U+1F1F6 U+1F1E6",
    "label": "Qatar",
    "emoji": "🇶🇦"
  },
  {
    "value": "RE",
    "unicode": "U+1F1F7 U+1F1EA",
    "label": "Réunion",
    "emoji": "🇷🇪"
  },
  {
    "value": "RO",
    "unicode": "U+1F1F7 U+1F1F4",
    "label": "Romania",
    "emoji": "🇷🇴"
  },
  {
    "value": "RS",
    "unicode": "U+1F1F7 U+1F1F8",
    "label": "Serbia",
    "emoji": "🇷🇸"
  },
  {
    "value": "RU",
    "unicode": "U+1F1F7 U+1F1FA",
    "label": "Russia",
    "emoji": "🇷🇺"
  },
  {
    "value": "RW",
    "unicode": "U+1F1F7 U+1F1FC",
    "label": "Rwanda",
    "emoji": "🇷🇼"
  },
  {
    "value": "SA",
    "unicode": "U+1F1F8 U+1F1E6",
    "label": "Saudi Arabia",
    "emoji": "🇸🇦"
  },
  {
    "value": "SB",
    "unicode": "U+1F1F8 U+1F1E7",
    "label": "Solomon Islands",
    "emoji": "🇸🇧"
  },
  {
    "value": "SC",
    "unicode": "U+1F1F8 U+1F1E8",
    "label": "Seychelles",
    "emoji": "🇸🇨"
  },
  {
    "value": "SD",
    "unicode": "U+1F1F8 U+1F1E9",
    "label": "Sudan",
    "emoji": "🇸🇩"
  },
  {
    "value": "SE",
    "unicode": "U+1F1F8 U+1F1EA",
    "label": "Sweden",
    "emoji": "🇸🇪"
  },
  {
    "value": "SG",
    "unicode": "U+1F1F8 U+1F1EC",
    "label": "Singapore",
    "emoji": "🇸🇬"
  },
  {
    "value": "SH",
    "unicode": "U+1F1F8 U+1F1ED",
    "label": "St. Helena",
    "emoji": "🇸🇭"
  },
  {
    "value": "SI",
    "unicode": "U+1F1F8 U+1F1EE",
    "label": "Slovenia",
    "emoji": "🇸🇮"
  },
  {
    "value": "SJ",
    "unicode": "U+1F1F8 U+1F1EF",
    "label": "Svalbard & Jan Mayen",
    "emoji": "🇸🇯"
  },
  {
    "value": "SK",
    "unicode": "U+1F1F8 U+1F1F0",
    "label": "Slovakia",
    "emoji": "🇸🇰"
  },
  {
    "value": "SL",
    "unicode": "U+1F1F8 U+1F1F1",
    "label": "Sierra Leone",
    "emoji": "🇸🇱"
  },
  {
    "value": "SM",
    "unicode": "U+1F1F8 U+1F1F2",
    "label": "San Marino",
    "emoji": "🇸🇲"
  },
  {
    "value": "SN",
    "unicode": "U+1F1F8 U+1F1F3",
    "label": "Senegal",
    "emoji": "🇸🇳"
  },
  {
    "value": "SO",
    "unicode": "U+1F1F8 U+1F1F4",
    "label": "Somalia",
    "emoji": "🇸🇴"
  },
  {
    "value": "SR",
    "unicode": "U+1F1F8 U+1F1F7",
    "label": "Suriname",
    "emoji": "🇸🇷"
  },
  {
    "value": "SS",
    "unicode": "U+1F1F8 U+1F1F8",
    "label": "South Sudan",
    "emoji": "🇸🇸"
  },
  {
    "value": "ST",
    "unicode": "U+1F1F8 U+1F1F9",
    "label": "São Tomé & Príncipe",
    "emoji": "🇸🇹"
  },
  {
    "value": "SV",
    "unicode": "U+1F1F8 U+1F1FB",
    "label": "El Salvador",
    "emoji": "🇸🇻"
  },
  {
    "value": "SX",
    "unicode": "U+1F1F8 U+1F1FD",
    "label": "Sint Maarten",
    "emoji": "🇸🇽"
  },
  {
    "value": "SY",
    "unicode": "U+1F1F8 U+1F1FE",
    "label": "Syria",
    "emoji": "🇸🇾"
  },
  {
    "value": "SZ",
    "unicode": "U+1F1F8 U+1F1FF",
    "label": "Swaziland",
    "emoji": "🇸🇿"
  },
  {
    "value": "TA",
    "unicode": "U+1F1F9 U+1F1E6",
    "label": "Tristan da Cunha",
    "emoji": "🇹🇦"
  },
  {
    "value": "TC",
    "unicode": "U+1F1F9 U+1F1E8",
    "label": "Turks & Caicos Islands",
    "emoji": "🇹🇨"
  },
  {
    "value": "TD",
    "unicode": "U+1F1F9 U+1F1E9",
    "label": "Chad",
    "emoji": "🇹🇩"
  },
  {
    "value": "TF",
    "unicode": "U+1F1F9 U+1F1EB",
    "label": "French Southern Territories",
    "emoji": "🇹🇫"
  },
  {
    "value": "TG",
    "unicode": "U+1F1F9 U+1F1EC",
    "label": "Togo",
    "emoji": "🇹🇬"
  },
  {
    "value": "TH",
    "unicode": "U+1F1F9 U+1F1ED",
    "label": "Thailand",
    "emoji": "🇹🇭"
  },
  {
    "value": "TJ",
    "unicode": "U+1F1F9 U+1F1EF",
    "label": "Tajikistan",
    "emoji": "🇹🇯"
  },
  {
    "value": "TK",
    "unicode": "U+1F1F9 U+1F1F0",
    "label": "Tokelau",
    "emoji": "🇹🇰"
  },
  {
    "value": "TL",
    "unicode": "U+1F1F9 U+1F1F1",
    "label": "Timor-Leste",
    "emoji": "🇹🇱"
  },
  {
    "value": "TM",
    "unicode": "U+1F1F9 U+1F1F2",
    "label": "Turkmenistan",
    "emoji": "🇹🇲"
  },
  {
    "value": "TN",
    "unicode": "U+1F1F9 U+1F1F3",
    "label": "Tunisia",
    "emoji": "🇹🇳"
  },
  {
    "value": "TO",
    "unicode": "U+1F1F9 U+1F1F4",
    "label": "Tonga",
    "emoji": "🇹🇴"
  },
  {
    "value": "TR",
    "unicode": "U+1F1F9 U+1F1F7",
    "label": "Turkey",
    "emoji": "🇹🇷"
  },
  {
    "value": "TT",
    "unicode": "U+1F1F9 U+1F1F9",
    "label": "Trinidad & Tobago",
    "emoji": "🇹🇹"
  },
  {
    "value": "TV",
    "unicode": "U+1F1F9 U+1F1FB",
    "label": "Tuvalu",
    "emoji": "🇹🇻"
  },
  {
    "value": "TW",
    "unicode": "U+1F1F9 U+1F1FC",
    "label": "Taiwan",
    "emoji": "🇹🇼"
  },
  {
    "value": "TZ",
    "unicode": "U+1F1F9 U+1F1FF",
    "label": "Tanzania",
    "emoji": "🇹🇿"
  },
  {
    "value": "UA",
    "unicode": "U+1F1FA U+1F1E6",
    "label": "Ukraine",
    "emoji": "🇺🇦"
  },
  {
    "value": "UG",
    "unicode": "U+1F1FA U+1F1EC",
    "label": "Uganda",
    "emoji": "🇺🇬"
  },
  {
    "value": "UM",
    "unicode": "U+1F1FA U+1F1F2",
    "label": "U.S. Outlying Islands",
    "emoji": "🇺🇲"
  },
  {
    "value": "UN",
    "unicode": "U+1F1FA U+1F1F3",
    "label": "United Nations",
    "emoji": "🇺🇳"
  },
  {
    "value": "US",
    "unicode": "U+1F1FA U+1F1F8",
    "label": "United States",
    "emoji": "🇺🇸"
  },
  {
    "value": "UY",
    "unicode": "U+1F1FA U+1F1FE",
    "label": "Uruguay",
    "emoji": "🇺🇾"
  },
  {
    "value": "UZ",
    "unicode": "U+1F1FA U+1F1FF",
    "label": "Uzbekistan",
    "emoji": "🇺🇿"
  },
  {
    "value": "VA",
    "unicode": "U+1F1FB U+1F1E6",
    "label": "Vatican City",
    "emoji": "🇻🇦"
  },
  {
    "value": "VC",
    "unicode": "U+1F1FB U+1F1E8",
    "label": "St. Vincent & Grenadines",
    "emoji": "🇻🇨"
  },
  {
    "value": "VE",
    "unicode": "U+1F1FB U+1F1EA",
    "label": "Venezuela",
    "emoji": "🇻🇪"
  },
  {
    "value": "VG",
    "unicode": "U+1F1FB U+1F1EC",
    "label": "British Virgin Islands",
    "emoji": "🇻🇬"
  },
  {
    "value": "VI",
    "unicode": "U+1F1FB U+1F1EE",
    "label": "U.S. Virgin Islands",
    "emoji": "🇻🇮"
  },
  {
    "value": "VN",
    "unicode": "U+1F1FB U+1F1F3",
    "label": "Vietnam",
    "emoji": "🇻🇳"
  },
  {
    "value": "VU",
    "unicode": "U+1F1FB U+1F1FA",
    "label": "Vanuatu",
    "emoji": "🇻🇺"
  },
  {
    "value": "WF",
    "unicode": "U+1F1FC U+1F1EB",
    "label": "Wallis & Futuna",
    "emoji": "🇼🇫"
  },
  {
    "value": "WS",
    "unicode": "U+1F1FC U+1F1F8",
    "label": "Samoa",
    "emoji": "🇼🇸"
  },
  {
    "value": "XK",
    "unicode": "U+1F1FD U+1F1F0",
    "label": "Kosovo",
    "emoji": "🇽🇰"
  },
  {
    "value": "YE",
    "unicode": "U+1F1FE U+1F1EA",
    "label": "Yemen",
    "emoji": "🇾🇪"
  },
  {
    "value": "YT",
    "unicode": "U+1F1FE U+1F1F9",
    "label": "Mayotte",
    "emoji": "🇾🇹"
  },
  {
    "value": "ZA",
    "unicode": "U+1F1FF U+1F1E6",
    "label": "South Africa",
    "emoji": "🇿🇦"
  },
  {
    "value": "ZM",
    "unicode": "U+1F1FF U+1F1F2",
    "label": "Zambia",
    "emoji": "🇿🇲"
  },
  {
    "value": "ZW",
    "unicode": "U+1F1FF U+1F1FC",
    "label": "Zimbabwe",
    "emoji": "🇿🇼"
  }
]