import React from "react"
import styled, { ThemeContext } from "styled-components"
import Section from "../../../resuable/section/section"
import { dimensions, colors } from "../../../../utils/styles/styles"

import Section1 from "./seo-report-preview-section-1/seo-report-preview-section-1"
import Section2 from "./seo-report-preview-section-2/seo-report-preview-section-2"
import Section3 from "./seo-report-preview-section-3/seo-report-preview-section-3"

const StyledSeoReportPreview = styled.div`
  width: 100%;
  max-width: ${dimensions.siteWidth};
  div {
    height: 51rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2 {
      font-size: 36px;
      font-weight: normal;
      color: #791fcd;
    }
    p {
      font-size: 18px;
    }
  }
  @media only screen and (max-width: 480px) {
    margin: 4rem auto;
    div {
      height: 11rem;
      h2 {
        font-size: 24px;
      }
      p {
        font-size: 14px;
      }
    }
  }
`

const SeoReportPreview = props => {
  return (
    <Section fullwidth={true} padding="50px 10px">
      <StyledSeoReportPreview>
        <Section1 />
        <Section2 />
        <Section3 />
      </StyledSeoReportPreview>
    </Section>
  )
}

SeoReportPreview.defaultProps = {}

export default SeoReportPreview
