import React, { useState } from "react"
import styled, { ThemeContext } from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import useWindowSize from "../../../../hooks/useWindowSize"

const PreviewSection1 = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 15rem;
  .seo-report-prev-sec-1-bg {
    position: relative;
    margin-right: auto;
    width: 81%;
  }
  .seo-report-prev-sec-1-content {
    position: absolute;
    margin-left: 60%;
  }
  @media only screen and (max-width: 480px) {
    margin-bottom: 12rem;
    .seo-report-prev-sec-1-bg {
      display: none;
    }
    .seo-report-prev-sec-1-content {
      margin: auto 12%;
    }
  }
`

const Section1 = props => {
  const data = useStaticQuery(graphql`
    query SeoReportPreviewImg1 {
      images: file(
        relativePath: {
          eq: "seo-report/seo-report-preview/seo-report-preview-1.png"
        }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <PreviewSection1>
      {useWindowSize().width > 480 && (
        <Img
          fluid={data.images.childImageSharp.fluid}
          alt="Optimize your SEO"
          className="seo-report-prev-sec-1-bg"
        />
      )}
      <div className="seo-report-prev-sec-1-content">
        <h2>Know how to make google love your page</h2>
        <p>
          Starting from your page performance in search engines, vulnerabilities
          to fix and suggestions to optimize your page for ranking top,
          LeadMirror 360 Audit does the job for you.
        </p>
      </div>
    </PreviewSection1>
  )
}

Section1.defaultProps = {}

export default Section1
