import React from "react"
import Layout from "../../components/resuable/layout/layout"
import SeoReportHeader from "../../components/page/seo-report-components/seo-report-header/seo-report-header"
import SeoReportClientsSection from "../../components/page/seo-report-components/seo-report-clients-section/seo-report-clients-section"
import SeoReportAnalysisTool from "../../components/page/seo-report-components/seo-report-analysis-tool-section/seo-report-analysis-tool-section"
import SeoReportPreview from "../../components/page/seo-report-components/seo-report-preview-section/seo-report-preview-section" 
import SeoReportBlogs from "../../components/page/seo-report-components/seo-report-blog-section/seo-report-blog-section" 
import SeoReportImproveSeo from "../../components/page/seo-report-components/seo-report-improve-seo/seo-report-improve-seo" 
import SEO from "../../components/resuable/seo/seo"
import { graphql } from "gatsby"

const SeoReport = ({ data }) => {
  const { yoast_meta } = data.wordpressPage
  return (
    <Layout theme="light" isFooterShown={true}>
      <SEO
        title={yoast_meta.yoast_wpseo_title}
        description={yoast_meta.yoast_wpseo_metadesc}
      />
      <SeoReportHeader />
      <SeoReportClientsSection />
      <SeoReportAnalysisTool />
      <SeoReportPreview />
      <SeoReportBlogs />
      <SeoReportImproveSeo />
    </Layout>
  )
}

export const query = graphql`
  query MyQuery {
    wordpressPage(title: { eq: "SEO Report" }) {
      title
      yoast_meta {
        yoast_wpseo_metadesc
        yoast_wpseo_title
      }
    }
  }
`

export default SeoReport
