import React from "react"
import styled from "styled-components"
import SeoReportForm from "./seo-report-form/seo-report-form"
import { colors } from "../../../../utils/styles/styles"
import DotGrid from "../../../../images/patterns/dot-grid/dot-grid.png"
import SeoReportProcess from "./seo-report-process/seo-report-process"

const StyledSeoReportHeader = styled.header`
  width: 100%;
  margin: 0px auto;
  padding: 0px 0px;
  display: flex;
  flex-direction: column;
  background: url(${DotGrid});
  background-repeat: repeat;
  background-position: center;
  background-attachment: fixed;
  div.container__text {
    padding: 10rem 20px 2rem;
    h1,
    h2 {
      text-align: center;
    }
    h1 {
      font-size: 6rem;
      color: #222;
    }
    h2 {
      font-size: 1.8rem;
      font-weight: 400;
      color: ${colors.lightGray};
    }
  }
  svg {
  }
  @media (max-width: 768px) {
    div.container__text {
      h1 {
        font-size: 30px;
      }
      h2 {
        font-size: 18px;
      }
    }
  }
`

const SeoReportHeader = props => {
  return (
    <StyledSeoReportHeader>
      <div className="container__text">
        <h1>Free 360 SEO Audit Report</h1>
        <h2>
          Enter your targeted page URL to generate detailed SEO audit report
        </h2>
      </div>
      <SeoReportForm />
      <SeoReportProcess />
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill={colors.priDark}
          fill-opacity="1"
          d="M0,64L60,101.3C120,139,240,213,360,218.7C480,224,600,160,720,154.7C840,149,960,203,1080,208C1200,213,1320,171,1380,149.3L1440,128L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
        ></path>
      </svg>
    </StyledSeoReportHeader>
  )
}

SeoReportHeader.defaultProps = {}

export default SeoReportHeader
