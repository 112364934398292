import React from "react"
import styled, { ThemeContext } from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Section from "../../../resuable/section/section"
import { dimensions, colors } from "../../../../utils/styles/styles"

import Row from "../../../resuable/row/row"

const StyledSeoReportClientsSection = styled.div`
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 3rem;
  .clients-heading {
    margin-bottom: 30px;
    h2 {
      font-size: 36px;
      font-weight: 400;
      text-align: center;
      color: #000;
    }
  }
  .clients-img {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row;
    flex-wrap: wrap;
    margin-top: 5rem;
    div {
      flex-shrink: 0;
      margin: auto 2rem;
      width: 187px;
      height: 112px;
    }
  }
  @media only screen and (max-width: 480px) {
    .clients-heading {
      margin-top: 30px;
      h2 {
        font-size: 24px;
      }
    }
    .clients-img {
      div {
        width: 133px;
        height: 80px;
      }
    }
  }
`

const SeoReportClientsSection = props => {
  const data = useStaticQuery(graphql`
    query SeoReportClientsImg {
      img1: file(
        relativePath: { eq: "seo-report/clients/Aries-Solutions.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img2: file(relativePath: { eq: "seo-report/clients/Rajarani.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img3: file(relativePath: { eq: "seo-report/clients/YourDreamTale.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img4: file(relativePath: { eq: "seo-report/clients/Mavrik.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img5: file(relativePath: { eq: "seo-report/clients/cytomol.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Section fullwidth={true} padding="50px 10px">
      <StyledSeoReportClientsSection>
        <Row className="clients-heading">
          <h2>Trusted and used by</h2>
        </Row>
        <Row className="clients-img">
          <Img fluid={data.img1.childImageSharp.fluid} alt="Aries Solutions" />
          <Img fluid={data.img2.childImageSharp.fluid} alt="Sri Raja Rani" />
          <Img fluid={data.img3.childImageSharp.fluid} alt="Your Dream Tale" />
          <Img fluid={data.img4.childImageSharp.fluid} alt="Mavrik" />
          <Img fluid={data.img5.childImageSharp.fluid} alt="Cytomol" />
        </Row>
      </StyledSeoReportClientsSection>
    </Section>
  )
}

SeoReportClientsSection.defaultProps = {}

export default SeoReportClientsSection
