// TODO make functions pure

export const isEmailValid = (email) => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (email === '') {
    return true
  } else {
    return regex.test(String(email).toLowerCase());
  }
}

export const isPhoneValid = (phone) => {
  const regex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

  if (phone === '') {
    return true
  } else {
    return regex.test(String(phone).toLowerCase());
  }
}

export const allFormFieldsFilled = (input) => {
  const inputArray = Object.values(input);
  let emptyInputs = 0;
  inputArray.forEach((el) => {
    if (el === '') {
      emptyInputs += 1;
    }
  });
  if (emptyInputs !== 0) {
    return false;
  } else {
    return true;
  }
}

export const isUrlValid = (inputUrl) => {
  const regex = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/;

  if (inputUrl === '') {
    return true;
  } else {
    return regex.test(inputUrl);
  }
}