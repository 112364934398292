import React from "react"
import styled, { ThemeContext } from "styled-components"
import Section from "../../../resuable/section/section"
import { dimensions, colors } from "../../../../utils/styles/styles"


const StyledSeoReportBlogs = styled.div`
  width: 100%;
  max-width: ${dimensions.siteWidth};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 2rem 0 6rem 0;
  padding: 0 12%;
  h2 {
    font-weight: 500;
    font-size: 36px;
    line-height: 125%;
  }
  p {
    font-size: 18px;
    line-height: 150%;
    width: 100%;
  }
  a {
    span {
      font-weight: bold;
      font-size: 18px;
      line-height: 150%;
      text-align: center;
      text-decoration-line: underline;
    }
  }
  @media only screen and (max-width: 480px) {
    margin: 8rem auto;
    h2 {
      font-size: 18px;
      font-weight: 600;
    }
    p {
      font-size: 12px;
    }
  }
`

const SeoReportBlog = props => {
  return (
    <Section fullwidth={true} padding="50px 10px">
      <StyledSeoReportBlogs>
        <h2>Why is the value of Technical SEO?</h2>
        <p>
          Now just creating quality content is not enough, If your page is seen
          by users neither shown by Google. Critical aspects like UX &
          indexation of a webpage should be prioritized in SEO to make your
          pages liked by users & search engines.
        </p>
        <a href="#">
          <span>Read Blog Post</span>
        </a>
      </StyledSeoReportBlogs>
    </Section>
  )
}

SeoReportBlog.defaultProps = {}

export default SeoReportBlog
