import React, { useState } from "react"
import styled from "styled-components"
import { ButtonType } from "../../../../resuable/button"
import { useEffect } from "react"
import { useRef } from "react"
import { colors, fonts } from "../../../../../utils/styles/styles"
import { countryList } from "../../../../../utils/helpers/countries"
import {
  getUserGeoLocation,
  getUserCountry,
} from "../../../../../utils/helpers/geolocation"
import {
  isUrlValid,
  isPhoneValid,
} from "../../../../../utils/sanitization/form-validation"

const StyledSeoReportForm = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  form {
    width: 100%;
    max-width: 850px;
    margin-bottom: 10px;
    display: flex;
    div.container__inputs {
      width: 100%;
      height: 48px;
      display: flex;
      align-items: stretch;
      border: 4px solid #ccc;
      border-right: 0px;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      select,
      input {
        box-sizing: border-box;
        padding: 0px 16px;
        height: 100%;
        border: none;
        border-radius: 0px;
        font-size: 14px;
        font-family: ${fonts.pri};
        &:focus {
          outline: none;
          background: #fffede;
        }
      }
      select.country {
        width: 20%;
        border-right: 4px solid #ccc;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
      }
      input.website {
        flex-basis: 45%;
        border-right: 4px solid #ccc;
      }
      input.contact {
        flex-basis: 35%;
        /* display: none; */
      }
    }
    button {
      box-shadow: none;
      border-radius: 0px;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      line-height: 100%;
    }
  }
  div.feedback {
    position: absolute;
    bottom: 0px;
    span {
      padding: 4px;
      background: red;
      color: #fff;
      border-radius: 20px;
      text-align: center;
      font-size: 10px;
      transition: 1s all;
    }
  }
  @media (max-width: 768px) {
    padding: 0 20px;
    form {
      flex-direction: column;
      align-items: center;
      div.container__inputs {
        height: auto;
        flex-direction: column;
        border-radius: 0px;
        border: 4px solid #ccc;
        select,
        input {
          height: 48px;
          border: none;
        }
        input {
          padding: 10px 8px;
          &:not(:last-child) {
            /* margin-bottom: 10px; */
          }
        }
        select.country {
          width: 100%;
          border-right: none;
        }
        input.website {
          height: 48px;
          flex: 1 1 50%;
          border-right: none;
        }
      }
      button {
        margin-top: 10px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-radius: 30px;
      }
    }
  }
`

const SeoReportForm = props => {
  const [url, setUrl] = useState("")
  const [country, setCountry] = useState("US")
  const [contact, setContact] = useState("")
  const [submitStatus, setSubmitStatus] = useState("")
  // Input Ref for url input
  const inputEl = useRef(null)
  useEffect(() => {
    // Geolocation API
    const fetchUserCountry = async () => {
      let country = await getUserCountry()
      console.log(country)
      setCountry(country)
    }
    fetchUserCountry()
    // Focus on Url field
    inputEl.current.focus()
  }, [])
  const handleUrlChange = e => {
    setUrl(e.target.value)
  }
  const handleCountryChange = e => {
    setCountry(e.target.value)
  }
  const handleContactChange = e => {
    setContact(e.target.value)
  }

  // Submit Action
  const handleSubmit = e => {
    e.preventDefault()
    // Check to see if both fields are filled
    if (url === "" && contact === "") {
      return
    }

    // Check to see url is valid
    if (isUrlValid(url) && isPhoneValid(contact)) {
      // Go ahead and submit
      window.open(
        `https://www.leadmirror.com/tool/seo-report/g?country=${country}&email=${contact}&utm_campaign=&url=${url}`
      )
      // Resets Input
      setUrl("")
      setContact("")
      setSubmitStatus("")
    } else if (!isUrlValid(url) && !isPhoneValid(contact)) {
      // If both are invalid show error message "The Url and phone number you have entered is invalid"
      setSubmitStatus("The Url and phone no you have entered is invalid")
    } else if (!isUrlValid(url)) {
      // If url is invalid "The Url you have entered is wrong"
      setSubmitStatus("The Url you have entered is invalid")
    }
    if (!isPhoneValid(contact)) {
      // If phone is invalid "The Phone number you have entered is wrong"
      setSubmitStatus("The Phone no you have entered is invalid")
    }
  }

  return (
    <StyledSeoReportForm>
      <form onSubmit={handleSubmit}>
        <div className="container__inputs">
          {/* Country Field */}
          <select
            className="country"
            value={country}
            onChange={handleCountryChange}
          >
            {countryList.map((country, index) => (
              <option key={index} value={country.value}>
                {country.label}
              </option>
            ))}
          </select>

          {/* Website/Url  */}
          <input
            className="website"
            type="text"
            placeholder="https://website.com (Your Website)"
            ref={inputEl}
            value={url}
            onChange={handleUrlChange}
            required
          />

          {/* Phone */}
          <input
            className="contact"
            type="tel"
            placeholder="+1-965-447-156 (Your Phone)"
            value={contact}
            onChange={handleContactChange}
            required
          />
        </div>

        {/* Submit Button */}
        <ButtonType.Base variant="primary">Submit</ButtonType.Base>
      </form>
      {submitStatus !== "" && (
        <div className="feedback">
          <span className="message">{submitStatus}</span>
        </div>
      )}
    </StyledSeoReportForm>
  )
}

SeoReportForm.defaultProps = {}

export default SeoReportForm
