import React, { useState } from "react"
import styled, { ThemeContext } from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import useWindowSize from "../../../../hooks/useWindowSize"

const PreviewSection3 = styled.div`
  width: 100%;
  position: relative;
  .seo-report-prev-sec-3-bg {
    position: relative;
    margin-right: auto;
    width: 81%;
  }
  .seo-report-prev-sec-3-content {
    position: absolute;
    margin-left: 60%;
  }
  @media only screen and (max-width: 480px) {
    .seo-report-prev-sec-3-content {
      margin: auto 12%;
    }
    .seo-report-prev-sec-3-bg {
      display: none;
    }
  }
`

const Section3 = props => {
  const data = useStaticQuery(graphql`
    query SeoReportPreviewImg3 {
      images: file(
        relativePath: {
          eq: "seo-report/seo-report-preview/seo-report-preview-3.png"
        }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <PreviewSection3>
      {useWindowSize().width > 480 && (
        <Img
          fluid={data.images.childImageSharp.fluid}
          alt="Optimize your SEO"
          className="seo-report-prev-sec-3-bg"
        />
      )}
      <div className="seo-report-prev-sec-3-content">
        <h2>Please crawlers to get indexed faster</h2>
        <p>
          With comprehensive readability insights, Now make search engines
          understand your page with ease and index faster for quick turn-around
          in your organic ranks.
        </p>
      </div>
    </PreviewSection3>
  )
}

Section3.defaultProps = {}

export default Section3
